import React from 'react';

function Footer() {
  return (
    <footer>
      <p>© 2024 FaucetPay Airdrops. Todos os direitos reservados.</p>
    </footer>
  );
}

export default Footer;
