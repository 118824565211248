import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import '../App.css';

const Premio = () => {
  const [endereco, setEndereco] = useState('');
  const [mensagem, setMensagem] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedAddress = localStorage.getItem('address');
    if (storedAddress) {
      setEndereco(storedAddress);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      //alert(`Sending address: ${endereco}`);
      const response = await axios.post('https://freedropspay.icu/api/submit', {
        address: endereco
      });
      if (response.status === 200) {
        setMensagem('Successful transaction!');
      } else {
        setMensagem('Transaction error. Try again.');
      }
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.message === 'Intervalo de 5 minutos entre as transações não respeitado.') {
        setMensagem('Please wait 5 minutes before trying again.');
      } else {
        console.error('Erro ao enviar solicitação:', error);
        setMensagem('Server error. Try again later.');
      }
    } finally {
      // Inicia o temporizador de 15 segundos
      setTimeout(() => {
        navigate('/');
      }, 15000); // 15000 ms = 15 segundos
    }
  };

  return (
    <div className="App">
      <Header />
      <div className="content">
        <h1>Receive your Prize!</h1>
        <p>Congratulations on getting this far! Now, submit your address to receive your prize.</p>
        <form onSubmit={handleSubmit}>
          <label>
          Address MATIC:
            <input type="text" value={endereco} readOnly className="input-address" />
          </label>
          <button type="submit" className="submit-button">To send</button>
        </form>
        {mensagem && <p className="message">{mensagem}</p>}
      </div>

      
      <div
        dangerouslySetInnerHTML={{
          __html: `<script type="text/javascript" src="https://udbaa.com/bnr.php?section=General&pub=725224&format=728x90&ga=g"></script>
                   <noscript><a href="https://yllix.com/publishers/725224" target="_blank"><img src="//ylx-aff.advertica-cdn.com/pub/728x90.png" style="border:none;margin:0;padding:0;vertical-align:baseline;" alt="ylliX - Online Advertising Network" /></a></noscript>`
        }}
      />      

      <div
        dangerouslySetInnerHTML={{
          __html: `<script type="text/javascript" src="https://vdbaa.com/tun.php?section=General&pt=8&pub=725224&ga=g"></script>`
        }}
      />

      <Footer />
    </div>
  );
};

export default Premio;
