import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import planeImage from '../assets/Faucet_Cloused.png';
import planeImage2 from '../assets/Faucet_Cloused.png';
import airdropImage from '../assets/airdrop.png';
import axios from 'axios'; // Import Axios
import '../App.css';

const Inicio = () => {
  const [address, setAddress] = useState("");
  const [news, setNews] = useState([]); // State para as notícias
  const [showFirstImage, setShowFirstImage] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const storedAddress = localStorage.getItem('address');
    if (storedAddress) {
      setAddress(storedAddress);
    }

    const interval = setInterval(() => {
      setShowFirstImage(prevShowFirstImage => !prevShowFirstImage);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    alert("Faucet Cloused")
  };

  const handleAirdropClick = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expressão regular para validar e-mail
    if (emailRegex.test(address)) {
      //localStorage.setItem('address', address);
      //navigate('/antibot');
      alert("Faucet Cloused")
    } else {
      alert('Por favor, insira um endereço de e-mail válido.');
    }
  };

  return (
    <div className="App">
      <Header />
      <div className="content">
        <h1>Welcome to FreeDropsPay!</h1>
        
        <p>Embark on this journey and win amazing prizes every 5 minutes!</p>
        <div className="input-container">
          <input
            type="text"
            placeholder="Enter your cryptocurrency address"
            value={address}
            onChange={handleAddressChange}
            className="input-address"
            style={{ backgroundColor: 'rgba(255, 255, 0, 0.5)' }} // Fundo amarelo opaco suave
          />
        </div>
        <p>Enter your email registered with FaucetPay above</p>
        <p>2-Click on the plane that passes on the screen to start and win prizes!</p>
        <div className="animation-container">
          <img
            src={showFirstImage ? planeImage : planeImage2}
            alt="Animated plane"
            className="plane"
            onClick={handleAirdropClick}
            title="Click on the plane to continue"
          />
        </div>

        <div className="news-container">
          <h2>Latest News about MATIC</h2>
          
          <ul>
            {news.map((article, index) => (
              <li key={index}>
                <a href={article.link} target="_blank" rel="noopener noreferrer">
                  {article.title}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <h1>Top 3 Games with the Most Active Users that Use MATIC</h1>

        <div className="game">
          <h2>3. Cometh</h2>
          <figure>
            <a href="https://game.cometh.io/shop/mule?code=Q15GTB" target="_blank" rel="noopener noreferrer">
              <img src="https://open-graph.opensea.io/v1/collections/cometh-spaceships" alt="Cometh"/>
            </a>
            <figcaption>Click on the image above to go to the Cometh game and explore its features.</figcaption>
          </figure>
          <p>Cometh is a space strategy game where players can mine asteroids and earn MATIC rewards, as well as trade spaceship NFTs.</p>
          <p>The game combines elements of DeFi and NFTs, allowing players to participate in activities such as mining, piracy, and trading. Cometh has an active and engaged community, with a player base that continues to grow</p>
          <p>In terms of funding, Cometh raised a total of $10 million in two rounds of financing, with investors including Stake Capital Group and IDEO CoLab Ventures7. The game uses the MATIC token for transactions within the platform, and daily financial transactions reflect the high activity of players. Cometh continues to expand its features and attract new players, consolidating itself as one of the top games on the Polygon blockchain.</p>
          <p>Cometh is a space strategy game that can be played in web browsers, both on computers and mobile devices. It offers a fluid and accessible gaming experience without the need for additional downloads</p>
        </div>

        <div className="game">
          <h2>2. Zed Run</h2>
          <img src="https://maticz.com/admin/images/ogImage/zed-run-clone-script.png" alt="Zed Run"/>
          <p>Zed Run is a digital horse racing game where players can buy, breed and race NFT horses, using MATIC for in-game transactions.</p>
          <p>The game uses blockchain technology to guarantee the ownership and authenticity of horses, allowing players to participate in races and earn rewards. Zed Run has an active and growing user base, with thousands of players participating in races daily</p>
          <p>In terms of funding, Zed Run raised a total of $20 million in two rounds of funding, with notable investors such as Redbeard Ventures and TCG4. The game has also generated over $30 million in digital horse sales, standing out as a profitable and innovative platform in the blockchain5 gaming space. The financial movement within the game is significant, with daily transactions in dollars that reflect the popularity and engagement of players.</p>
          <p>Zed Run is also a cross-platform game that can be played on web browsers. It is compatible with computers and mobile devices, including iPhone and Android2. The game uses the Polygon blockchain for transactions and data storage.</p>
        </div>

        
        <div className="game">
          <h2>1. Aavegotchi</h2>
          <img src="https://usethebitcoin.com/wp-content/uploads/2024/04/aavegotchi.png" alt="Aavegotchi"/>
          <p>Aavegotchi is a digital collectibles game where players can breed, train and compete with their Aavegotchis, which are NFTs based on the Polygon blockchain.</p>
          <p>Each Aavegotchi is an NFT that can be customized with various accessories and items, which are also NFTs. The game is governed by a DAO (decentralized autonomous organization), allowing players to participate in decisions about the future of the game. Aavegotchi has an active community of over 50,000 players</p>
          <p>In terms of financial fundraising, Aavegotchi has raised significant funds since its launch. The platform uses the GHST token, which is used for governance, purchasing NFTs and participating in in-game events. The market cap of the GHST token is approximately $54 million, with a daily trading volume of around $3 million2. Aavegotchi continues to attract investors and players, consolidating itself as one of the top games on the Polygon blockchain.</p>
          <p>Aavegotchi is a multiplatform game that can be played in web browsers, both on computers and mobile devices. It is accessible through the official website and does not require downloading specific applications.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Inicio;
