import React, { useEffect, useRef } from 'react';
import logo01Image from '../assets/logo_fpp_01.png';
import logo02Image from '../assets/Faucet_Cloused.png';
import { Helmet } from 'react-helmet';

function Header() {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;

    // Criar o script de configuração do banner
    const script1 = iframeDoc.createElement('script');
    script1.type = 'text/javascript';
    script1.innerHTML = `
      atOptions = {
        'key' : 'bb03d881bbde5a3b62831b5ae215b6c9',
        'format' : 'iframe',
        'height' : 90,
        'width' : 728,
        'params' : {}
      };
    `;
    iframeDoc.body.appendChild(script1);
    
    // Criar o script de invocação do banner
    const script2 = iframeDoc.createElement('script');
    script2.type = 'text/javascript';
    script2.src = '//www.topcreativeformat.com/bb03d881bbde5a3b62831b5ae215b6c9/invoke.js';
    iframeDoc.body.appendChild(script2);
    /*
    // Criar o script do Google AdSense
    const script3 = document.createElement('script');
    script3.async = true;
    script3.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7075576467048583';
    script3.crossOrigin = 'anonymous';
    document.body.appendChild(script3);

    return () => {
      document.body.removeChild(script3);
    };
    */
  }, []);

  return (
    <header>
      
      <iframe
        ref={iframeRef}
        width="728"
        height="90"
        frameBorder="0"
        scrolling="no"
        title="Ad"
        style={{ display: 'block', margin: '20px auto' }}
      ></iframe>
      <div>
          <img 
            src={logo01Image} 
            alt="logo_01" 
            title="Biscoito logo do site"
            className="logo-image2"
          />

          <img 
            src={logo02Image} 
            alt="logo_02" 
            title="Biscoito logo do site"
            className="logo-image2"
          />

        </div>
      <h1>
      Get free MATIC NOW !
      </h1>
    </header>
  );
}

export default Header;
