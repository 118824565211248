import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Inicio from './components/Inicio';
import Premio from './components/Premio';
import Antibot from './components/Antibot';
import AdSenseVerification from './components/AdSenseVerification';
import './App.css';

function App() {
  return (
    <>
      <AdSenseVerification />
      <Router>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/premio" element={<Premio />} />
          <Route path="/antibot" element={<Antibot />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
