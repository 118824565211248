import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import airdropImage from '../assets/airdrop.png';
import '../App.css';

const AntiBot = () => {
  const navigate = useNavigate();

  const handleAirdropClick = () => {
    //navigate('/premio');
    alert("Faucet Cloused")
  };

  return (
    <div className="App">
      <Header />
      <div className="content">
        <h1>Bot Protection</h1>
        <p>To ensure security, we need to verify that you are not a bot.</p>
        <p>You may have to click several times</p>
        <img
          src={airdropImage}
          alt="Airdrop"
          className="airdrop clickable"
          onClick={handleAirdropClick}
        />
        
      </div>
      
      <Footer />
    </div>
  );
};

export default AntiBot;